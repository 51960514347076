import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../src/assets/styles/scss/style.scss";
import Loading from "./components/loading/index";
import Protected from "./Protected";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./views/pages/login/Login"));
const Register = lazy(() => import("./views/pages/register/Register"));
const Page404 = lazy(() => import("./views/pages/page404/Page404"));
const Page500 = lazy(() => import("./views/pages/page500/Page500"));
const Users = lazy(() => import("./pages/company"));
const AddCompanyForm = lazy(() => import("./pages/company/companyAddForm"));

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/500" element={<Page500 />} />
          <Route path="/users/userList" element={<Protected Cmp={Users} />} />
          <Route path="/users/addform" element={<Protected Cmp={AddCompanyForm} />} />
          <Route path="*" element={<Protected Cmp={Home} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
